<template>
    <div class="p-grid">
        <div class="p-col-12">
            <div class="card">
                <h5>{{ $t('menu.editMarketGroups') }}</h5>
                <Accordion :activeIndex="0">
                    <AccordionTab :header="$t('markets.titles.groupDetails')">
                        <div class="p-grid">
                            <div class="p-col-12 p-md-6">
                                <div class="p-field p-grid" v-show="this.isEdit()">
                                    <label class="p-col-12 p-mb-2 p-md-3 p-mb-md-0">{{ $t('sportsSettings.name') }}</label>
                                    <div class="p-col-12 p-md-8">
                                        <InputText style="width: 100%" type="text" v-model="group.name" disabled />
                                    </div>
                                </div>
                                <div class="p-field p-grid">
                                    <label class="p-col-12 p-mb-2 p-md-3 p-mb-md-0">{{ $t('sportsSettings.displayName') }}</label>
                                    <div class="p-col-12 p-md-8">
                                        <InputText style="width: 100%" type="text" v-model="group.displayName" />
                                    </div>
                                </div>
                                <div class="p-field p-grid">
                                    <label class="p-col-12 p-mb-2 p-md-3 p-mb-md-0">{{ $t('sportsSettings.sortOrder') }}</label>
                                    <div class="p-col-12 p-md-8">
                                        <InputText style="width: 100%" type="text" v-model="group.sortOrder" />
                                    </div>
                                </div>
                            </div>
                            <div class="p-col-12 p-md-6"></div>
                        </div>
                    </AccordionTab>
                    <AccordionTab :header="$t('markets.titles.groupMarkets')">
                        <div class="card">
                            <div class="p-grid">
                                <div class="p-col-6 p-md-2" v-for="(market, index) in groupMarkets" :key="market.id + '_' + index">
                                    <div class="p-field-checkbox">
                                        <Checkbox :id="'checkOption' + index" :value="market.id" v-model="selectedMarkets" />
                                        <label :for="'checkOption' + index">{{ market.name }}</label>
                                    </div>
                                </div>
                            </div>
                            <Fieldset :legend="$t('markets.titles.addMarketsToGroup')" :toggleable="true" :collapsed="true">
                                <div class="p-grid">
                                    <div class="p-col-6 p-md-2" v-for="(market, index) in unselectedMarkets" :key="market.id + '_' + index">
                                        <div class="p-field-checkbox">
                                            <Checkbox :id="'checkOption' + index" :value="market.id" v-model="selectedMarkets" />
                                            <label :for="'checkOption' + index">{{ market.name }}</label>
                                        </div>
                                    </div>
                                </div>
                            </Fieldset>
                        </div>
                    </AccordionTab>
                </Accordion>
                <transition-group name="p-message" tag="div">
                    <Message :key="errorKeyCount" :severity="'error'" v-show="showError">{{ errorText }}</Message>
                </transition-group>
                <div class="p-col-12">
                    <Button @click="submitForm()" :label="$t('buttons.save')" class="p-button-raised p-button-success p-mr-2 p-mb-2" />
                    <Button :label="$t('buttons.cancel')" class="p-button-raised p-button-danger p-mr-2 p-mb-2" @click="goto(backRoute)" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { SportsSettingsApi } from '../../service/SportSettingsApi';
export default {
    data() {
        return {
            SportsSettingsApi: new SportsSettingsApi(),
            backRoute: 'marketGroups',
            errorKeyCount: 0,
            group: {
                marketIds: [],
            },
            sportsSettingsApi: new SportsSettingsApi(),
            showError: false,
            errorText: '',
            markets: [],
            marketsWithSelector: [],
            dataLoaded: false,
            addNewMarkets: false,
            newMarkets: false,
            searchModel: '',
            isAdd: false,
            disableSubmit: false,
            selectedMarkets: [],
            groupMarkets: [],
            unselectedMarkets: [],
        };
    },
    mounted() {
        this.SportsSettingsApi.getMarketsForGroups()
            .then((response) => {
                this.markets = response.data;
                if (this.$route.params.id !== '0') {
                    this.sportsSettingsApi.getGroup(this.$route.params.id).then((response) => {
                        this.group = response.data;
                        for (var i = 0; i < this.markets.length; i++) {
                            if (this.group.marketIds.indexOf(this.markets[i].MarketId) >= 0) {
                                this.groupMarkets.push({
                                    name: this.markets[i].DisplayName,
                                    id: this.markets[i].MarketId,
                                });
                                this.selectedMarkets.push(this.markets[i].MarketId);
                            } else {
                                this.unselectedMarkets.push({
                                    name: this.markets[i].DisplayName,
                                    id: this.markets[i].MarketId,
                                });
                            }
                        }
                        this.dataLoaded = true;
                    });
                } else {
                    for (var i = 0; i < this.markets.length; i++) {
                        this.unselectedMarkets.push({
                            name: this.markets[i].DisplayName,
                            id: this.markets[i].MarketId,
                        });
                    }
                    this.isAdd = true;
                    this.dataLoaded = true;
                    this.addNewMarkets = true;
                    this.newMarkets = true;
                }
            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    this.$store.commit('login/endUserSession');
                    this.goto('login', { session: 'false' });
                }
                this.errorText = error;
                this.showError = true;
                this.errorKeyCount++;
            });
    },
    methods: {
        submitForm: function () {
            this.disableSubmit = false;
            this.group.marketIds = this.selectedMarkets;
            this.SportsSettingsApi.updateGroup(this.group)
                .then(() => {
                    this.goto(this.backRoute);
                })
                .catch((error) => {
                    this.disableSubmit = false;
                    this.errorText = error;
                    this.showError = true;
                    this.errorKeyCount++;
                });
        },
        isEdit() {
            return this.$route.params.id !== '0';
        },
        showNewMarkets() {
            this.newMarkets = !this.newMarkets;
            return false;
        },
    },
};
</script>
